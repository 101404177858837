import memoizeIntlConstructor from "intl-format-cache";

import { SentryClient } from "@app/services/sentry-facade";
import { CurrencyCode } from "@app/types/currency";

import { Locales, SupportedLocale } from "../../config/locales";

interface FormatDateOptions {
  weekday?: "narrow" | "short" | "long";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "narrow" | "short" | "long";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
}

interface FormatTimeOptions {
  drop_minutes?: boolean;
  pad_zero?: boolean;
  lower_am_pm?: boolean;
  compact_display?: boolean;
}

function padZero(n: number): string {
  if (n < 10) {
    return "0" + n;
  } else {
    return "" + n;
  }
}

function noPad(n: number): string {
  return n.toString();
}

const AM_PM = {
  lower: { am: "am", pm: "pm" },
  upper: { am: "AM", pm: "PM" }
} as const;

const ENVIRONMENT_SUPPORTS_INTL = typeof Intl !== "undefined";

/**
 * Service that provides locale-aware date, time, number, and price formatting.
 * Under-the-hood, uses the `Intl` API but exposes wrapper methods with bug corrections
 * and performance improvements (memoization).
 *
 */
export default class IntlProviderService {
  public locale: string;
  public currency: CurrencyCode;
  private sentry: SentryClient | undefined;
  private use_fallback: boolean;
  public getDateTimeFormat: (
    locale: string,
    options: Intl.DateTimeFormatOptions
  ) => Pick<Intl.DateTimeFormat, "format">;
  private getNumberFormat: (
    locale: string,
    options?: Intl.NumberFormatOptions
  ) => Pick<Intl.NumberFormat, "format">;

  public constructor(options: {
    locale: string;
    currency: CurrencyCode;
    /** If provided, will log errors (ex: parsing errors) */
    sentry?: SentryClient;
    /** Used only for tests, detects environment support for Intl otherwise */
    use_fallback?: boolean;
  }) {
    this.locale = options.locale;
    this.currency = options.currency;
    this.sentry = options.sentry;
    this.use_fallback =
      typeof options.use_fallback === "boolean"
        ? options.use_fallback
        : !ENVIRONMENT_SUPPORTS_INTL;

    // Clients of this class should be using wrapper methods (ex: `formatDate`)
    // instead of `getDateTimeFormat` and `getNumberFormat` directly
    // They are still exposed as "public" as a convenience if necessary
    if (this.use_fallback) {
      this.getDateTimeFormat = getDateTimeFormatFallback;
      this.getNumberFormat = getNumberFormatFallback;
    } else {
      this.getDateTimeFormat = memoizeIntlConstructor(Intl.DateTimeFormat);
      this.getNumberFormat = memoizeIntlConstructor(Intl.NumberFormat);
    }

    this.formatHours = this.formatHours.bind(this);
  }

  public getLocalesThatUseMiles(): SupportedLocale[] {
    return [Locales.supported_locales.en.lang];
  }

  private _dateTimeFormatFactory(options: FormatDateOptions) {
    // Cherry-pick only allowed options
    const { weekday, year, month, day, hour, minute, second } = options;

    // Tell `Intl` to expect a UTC date (see JSDoc of this method for more info)
    const format_options = {
      weekday,
      year,
      month,
      day,
      hour,
      minute,
      second,
      timeZone: "UTC"
    };

    return this.getDateTimeFormat(this.locale, format_options);
  }

  /**
   * Format the date part of an ISO string for a given locale.
   *
   * Note: Under the hood this method uses `Date.UTC` to create the `Date` object passed to `Intl`.
   * The reason is we observed a bug where certain users would have their browser's `Date` and `Intl`
   * thinking they were in different timezones, causing `Intl` to convert the `Date` passed to it,
   * and ultimately displaying the wrong date to the user.
   * See {@link https://github.com/busbud/public-website/issues/3807}
   *
   * @param datetime ISO datetime (YYYY-MM-DDTHH:mm:ss) or date (YYYY-MM-DD)
   * @param [options]
   * @returns
   */
  public formatDate(
    datetime: string,
    options: FormatDateOptions = {}
  ): string | null {
    const parsed_date = this._parseDate(datetime);
    if (!parsed_date) {
      this.sentry &&
        this.sentry.captureException(
          new Error(`Failed to parse date from ${datetime}`)
        );
      return null;
    }

    // Note that months are zero-indexed for `Date` constructor
    const parsed_date_UTC = Date.UTC(
      parsed_date.year,
      parsed_date.month - 1,
      parsed_date.day
    );

    const date = new Date(parsed_date_UTC);
    return this._dateTimeFormatFactory(options).format(date);
  }

  /**
   *
   * Format the time of an ISO datetime for a given locale.
   *
   * We use a custom time formatting function (ex: to display a departure time),
   * similar to `Intl.DateTimeFormat`. This is to avoid a bug where wrong departure
   * times were displayed to certain users. See {@link IntlProvider.formatDate}.
   *
   * @param datetime ISO datetime (YYYY-MM-DDTHH:mm:ss) or time (HH:mm:ss)
   * @param [options]
   * @returns  Localized formatted time, or `null` if parsing failed
   */
  public formatTime(
    datetime: string,
    options: FormatTimeOptions = {}
  ): string | null {
    const default_options: Partial<FormatTimeOptions> = {
      lower_am_pm: true,
      compact_display: true
    };
    const _options = { ...default_options, ...options };
    const time = this._parseTime(datetime);
    if (!time) {
      this.sentry &&
        this.sentry.captureException(
          new Error(`Failed to parse time from ${datetime}`)
        );
      return null;
    }

    const locale = this.locale;

    // 7:30 AM, 3:15 PM
    if (locale === "en") {
      return this._formatTimeAmPm(time, _options);
    }

    if (locale === "en-ca") {
      return this._formatTimeAmPm(time, _options);
    }

    // 7:30, 15:15
    if (locale === "es-mx" || locale === "es") {
      return this._formatTimeIso(time, { ..._options, pad_zero: false });
    }

    // 7 h 30, see http://www.btb.termiumplus.gc.ca/redac-chap?lang=fra&lettr=chapsect2&info0=2.4.3
    if (locale === "fr-ca") {
      return this._formatTimeFrench(time, _options);
    }

    // 上午7:30, 下午3:15
    if (locale === "zh") {
      return this._formatTimeChinese(time, _options);
    }

    // Default/fallback format
    // Ex: 07:30, 15:15
    // Note that the fallback format should always pad the hours to avoid ambiguity
    return this._formatTimeIso(time, { ..._options, pad_zero: true });
  }

  /**
   * Format the hours of an ISO datetime for a given locale.
   * Special case for smart filters or wherever need to display hours only
   *
   * @param datetime ISO datetime (YYYY-MM-DDTHH:mm:ss) or time (HH:mm:ss)
   * @returns Localized formatted time, or `null` if parsing failed
   */
  public formatHours(datetime: string): string | null {
    return this.formatTime(datetime, { drop_minutes: true });
  }

  /**
   * Format a number according to locale conventions
   *
   * @param x Number to format
   * @param options
   * @returns Formatted number
   */
  public formatNumber(
    x: number,
    options: Intl.NumberFormatOptions = {}
  ): string {
    if (options.currencyDisplay && options.currencyDisplay === "narrowSymbol") {
      // Note: Remove if you need to use this option but double check the browser support
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
      throw new Error(
        "currencyDisplay: 'currencyDisplay' is not implemented for safari prior 14.1(OSX) 14.5(iOS) and is causing the page to crash"
      );
    }

    let formatter: Pick<Intl.NumberFormat, "format">;

    try {
      formatter = this.getNumberFormat(this.locale, options);
    } catch {
      // Some navigators have an Intl implementations but the UCI library installed with their OS used by Intl do not fully support NumberFormat API
      // so we need to fallback to a custom implementation
      formatter = getNumberFormatFallback(this.locale, options);
    }

    return formatter.format(x);
  }

  /**
   * Format a price in a locale and currency aware manner; drops cents by rounding up to the dollar
   *
   * @param price Amount in currency
   * @returns Formatted price with currency
   */
  public formatPriceNoCents(price: number): string {
    const formatter = this.getNumberFormat(this.locale, {
      style: "currency",
      currency: this.currency
    });

    const formatted = formatter.format(Math.ceil(price));

    // Remove cents when currency symbol is at start
    let display = formatted.replace(/([\D]00$)/, "");

    // Remove cents when currency symbol is at end
    if (!/\d$/.test(display)) {
      display = display.replace(/[\D]00(\D)/, "$1");
    }

    return display;
  }

  public getCurrencyName(currency: CurrencyCode): string {
    const currency_name = this.getNumberFormat(this.locale, {
      style: "currency",
      currency: currency,
      currencyDisplay: "name",
      maximumSignificantDigits: 1
    })
      .format(10)
      .replace(/\d/g, "")
      .trim();
    return currency_name.charAt(0).toUpperCase() + currency_name.substring(1);
  }

  /**
   * @param input ISO datetime (YYYY-MM-DDTHH:mm:ss) or date (YYYY-MM-DD)
   * @returns  Parsed date,
   *    or `null` if parsing failed.
   */
  private _parseDate(
    input: string
  ): { year: number; month: number; day: number } | null {
    if (input.indexOf("T") > -1) {
      input = input.split("T")[0];
    }

    const date_parts = input.split("-");

    if (date_parts.length !== 3) {
      return null;
    }

    const year = parseInt(date_parts[0], 10);
    const month = parseInt(date_parts[1], 10);
    const day = parseInt(date_parts[2], 10);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return null;
    }

    return {
      year: year,
      month: month,
      day: day
    };
  }

  /**
   * @param input ISO datetime (YYYY-MM-DDTHH:mm:ss) or time (HH:mm:ss) or datetime with timezone (YYYY-MM-DDTHH:mm:ss-HH:mm)
   * @returns Parsed time,
   *    or `null` if parsing failed
   */
  private _parseTime(input: string): { hours: number; minutes: number } | null {
    if (!input) {
      return null;
    }

    let parsed = input;

    if (parsed.indexOf("T") > -1) {
      const datetime_parts = parsed.split("T");

      if (datetime_parts.length !== 2) {
        return null;
      }

      parsed = datetime_parts[1];
    }

    if (parsed.indexOf("-") > -1) {
      parsed = parsed.split("-")[0];
    }

    const time_parts = parsed.split(":");

    if (time_parts.length !== 3) {
      return null;
    }

    const hours = parseInt(time_parts[0], 10);
    const minutes = parseInt(time_parts[1], 10);
    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }

    return {
      hours: hours,
      minutes: minutes
    };
  }

  private _formatTimeAmPm(
    time: { hours: number; minutes: number },
    options: FormatTimeOptions
  ): string {
    const { hours, minutes } = time;
    const minutes_string = options.drop_minutes ? "" : `:${padZero(minutes)}`;
    const formatHour = options.pad_zero ? padZero : noPad;
    const am_pm = options.lower_am_pm ? AM_PM.lower : AM_PM.upper;
    const spacing = options.compact_display ? "" : " ";

    switch (true) {
      case hours === 0 || hours === 24:
        return `12${minutes_string}${spacing}${am_pm.am}`;
      case hours < 12:
        return `${formatHour(hours)}${minutes_string}${spacing}${am_pm.am}`;
      case hours === 12:
        return `12${minutes_string}${spacing}${am_pm.pm}`;
      default:
        return `${formatHour(hours - 12)}${minutes_string}${spacing}${
          am_pm.pm
        }`;
    }
  }

  private _formatTimeFrench(
    time: { hours: number; minutes: number },
    options: FormatTimeOptions
  ): string {
    const { hours, minutes } = time;
    const minutes_string = options.drop_minutes ? "" : `h${padZero(minutes)}`;
    const formatHour = options.pad_zero ? padZero : noPad;

    return `${formatHour(hours)}${minutes_string}`;
  }

  private _formatTimeChinese(
    time: { hours: number; minutes: number },
    options: FormatTimeOptions
  ): string {
    const { hours, minutes } = time;
    const minutes_string = options.drop_minutes ? "" : `:${padZero(minutes)}`;
    const formatHour = options.pad_zero ? padZero : noPad;

    switch (true) {
      case hours === 0 || hours === 24:
        return `上午12${minutes_string}`;
      case hours < 12:
        return `上午${formatHour(hours)}${minutes_string}`;
      case hours === 12:
        return `下午12${minutes_string}`;
      default:
        return `下午${formatHour(hours - 12)}${minutes_string}`;
    }
  }

  private _formatTimeIso(
    time: { hours: number; minutes: number },
    options: FormatTimeOptions
  ): string {
    const { hours, minutes } = time;
    const minutes_string = options.drop_minutes ? "" : `:${padZero(minutes)}`;
    const formatHour = options.pad_zero ? padZero : noPad;

    return `${formatHour(hours)}${minutes_string}`;
  }
}

// Most of our user base use browsers that support `Intl`
// For the few still on older browsers, provide a simple fallback

// We ignore the `options` to keep this fallback simple
// eslint-disable-next-line no-unused-vars
function getDateTimeFormatFallback(
  _locale: string | undefined,
  _options: Intl.DateTimeFormatOptions | undefined
): Pick<Intl.DateTimeFormat, "format"> {
  return {
    format: function (date: Date): string {
      // YYYY-MM-DD
      return date.toISOString().slice(0, 10);
    }
  };
}

function getNumberFormatFallback(
  _locale: string | undefined,
  options: Intl.NumberFormatOptions | undefined
): Pick<Intl.NumberFormat, "format"> {
  if (options && options.style === "currency" && options.currency) {
    return {
      format: function (value: number): string {
        // Separated with special non-breaking space character
        return options.currency + " " + value;
      }
    };
  }

  return {
    format: function (value: number): string {
      return "" + value;
    }
  };
}

export { IntlProviderService };
