import { sanitizeUrl } from "@braintree/sanitize-url";

import { isNotNil } from "@app/helpers/is-not-nil";
import { BrowserStorage } from "@app/lib/bowser-storage/browser-storage";
import { SearchQuery } from "@app/types/search-types";

// Various utility client-side functions

/**
 * Polyfill for IE8/IE9/IE10 window.location.origin
 * {@see http://tosbourn.com/2013/08/javascript/a-fix-for-window-location-origin-in-internet-explorer/}
 *
 */
export function locationOrigin(): string {
  if (!window.location.origin) {
    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "")
    );
  }

  return window.location.origin;
}

/**
 * Check if current domain is "www.neredennereye.com"
 *
 */
export function isNNDomain(): boolean {
  if (window.location.hostname) {
    return window.location.hostname.includes("neredennereye.com");
  }
  return false;
}

/**
 * Check if user's browser has HTML5 History support
 *
 */
export function isHistorySupported(): boolean {
  return "pushState" in window.history;
}

/**
 *  Adds a new history entry with the given query appended
 *
 * @param additional_query
 */
export function pushToHistory(
  additional_query: Record<string, string> = {}
): void {
  if (!isHistorySupported()) {
    return;
  }

  const search = new URLSearchParams(window.location.search);

  for (const key in additional_query) {
    search.set(key, `${additional_query[key]}`);
  }

  const query_string = search.toString();

  window.history.pushState(
    {},
    "",
    query_string ? `?${query_string}` : window.location.href
  );
}

/**
 *  Replaces the last history entry with the given query appended
 *
 * @param query
 */
export function appendQueryToCurrentLocation(
  query: Record<string, string> | SearchQuery
): void {
  if (!isHistorySupported()) {
    return;
  }

  const current_params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const filtered_params = Object.entries(current_params).filter(([_, value]) =>
    isNotNil(value)
  );
  const filtered_query = Object.entries(query)
    .filter(([_, value]) => isNotNil(value))
    .map(([key, value]) => {
      if (typeof value !== "object") {
        return [key, value];
      }

      return Object.entries(value).flatMap(([k, v]) => [`${key}[${k}]`, v]);
    });

  const full_query = Object.fromEntries([
    ...filtered_params,
    ...filtered_query
  ]);

  const query_string = `?${new URLSearchParams(full_query).toString()}`;

  window.history.replaceState({}, "", query_string);
}

export function sanitizeQueryURL(
  url: string,
  allow_absolute_url: boolean = false
): string {
  let url_to_redirect = sanitizeUrl(url);

  // sanitizeUrl will return "about:blank" if the url is invalid
  url_to_redirect = url_to_redirect === "about:blank" ? "/" : url_to_redirect;

  const is_absolute_url = /^(http|https):\/\//.test(url);
  if (is_absolute_url && allow_absolute_url) {
    return url_to_redirect;
  }

  const url_to_redirect_obj = new URL(url_to_redirect, window.location.href);
  url_to_redirect_obj.host = window.location.host;
  url_to_redirect = url_to_redirect_obj.pathname + url_to_redirect_obj.search;

  return url_to_redirect;
}

/**
 * Function that sends a HEAD request to know if the target url is available
 * If the response is a redirection, the HEAD does not follow the redirection to check if the redirection leads to a broken page or not.
 * We don't follow the redirection since it can redirect to an external url and the HEAD request could be blocked by CORS therefore blocking the redirect even if the external url is valid.
 *
 * @param url
 * @param external
 * @param callbacks
 */
export async function isRedirectTargetAvailable(
  url: string,
  external: boolean = false
): Promise<boolean> {
  const sanitized_url = sanitizeQueryURL(url, external);

  try {
    const response = await fetch(sanitized_url, {
      method: "HEAD",
      redirect: "manual"
    });
    const is_redirect =
      response.type === "opaqueredirect" ||
      (response.status >= 300 && response.status < 400);

    return response.ok || is_redirect;
  } catch (err) {
    return false;
  }
}

export function redirect(url: string, external: boolean = false): void {
  // This is how we do client-side redirection across the app,
  // but TypeScript marks `window.location` as not being something
  // you can set. We may want to research if there are other, better
  // ways of doing client-side redirects.
  window.location.assign(sanitizeQueryURL(url, external));
}

export function postRedirect(url: string, data: Record<string, string>) {
  const form = document.createElement("form");
  document.body.appendChild(form);
  form.method = "post";
  form.action = url;
  Object.entries(data).forEach(([key, value]) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });
  form.submit();
}

/**
 * @param url
 * @param [target] Name of the open window. Can be a target of links and forms: "_blank", "_self", "_parent", "_top", or a window name. Not affecting the title
 * @param external
 */
export function open(
  url: string,
  target?: string,
  external: boolean = false
): Window | null {
  const new_window = window.open(sanitizeQueryURL(url, external), target);

  if (new_window) {
    new_window.opener = null;
  }

  return new_window;
}

/**
 * @param url
 * @param [target] Name of the open window.
 */
export function openAndFocusOn(url: string, target: string = "busbud"): Window {
  const new_window = window.open();
  // May have gotten blocked by a popup blocker
  if (!new_window) {
    throw new Error(`Cannot create a new window: ${target}, ${url}`);
  }
  new_window.location.assign(url);
  new_window.name = target;
  new_window.opener = null;
  new_window.blur();
  window.focus();
  return new_window;
}

export function refresh(): void {
  window.location.reload();
}

export function getClientToken(storage: BrowserStorage): string | null {
  const search = new URLSearchParams(global.window.location.search);
  const CLIENT_TOKEN_KEY = "client_token";
  const client_token = search.get(CLIENT_TOKEN_KEY);
  const stored_token = storage.getItem<string>(CLIENT_TOKEN_KEY);

  if (client_token && client_token !== stored_token) {
    storage.setItem(CLIENT_TOKEN_KEY, client_token);
    return client_token;
  }

  if (stored_token) {
    return stored_token;
  }

  return null;
}
